<template>
  <form
    class="filter-bar sticky-top"
    :class="[
      { 'filter-bar--visible': showFilters },
      { 'mb-3': showFilters },
    ]"
  >
    <div class="d-flex p-2 bg-body rounded shadow">
      <div class="d-flex flex-wrap gap-2">
        <checkbox-filter
          v-model="filters.isActivated"
          name="Actief"
          :options="new Map([
            [true, 'Ja'],
            [false, 'Nee'],
          ])"
        />
      </div>

      <div class="col-3 ms-auto">
        <material-input
          id="app-users-search"
          v-model="searchQuery"
          placeholder="Zoeken..."
          type="search"
        />
      </div>
    </div>
  </form>

  <div
    v-for="company in filteredCompanies"
    :key="company.id"
    class="mt-3 mb-3 col-lg-4 col-md-6"
  >
    <company-card
      :id="company.id"
      :board-member-function="company.boardMemberFunction"
      :image="company.profilePicture"
      :is-activated="company.isActivated"
      :name="company.name"
      :profession="company.profession"
      :company-name="company.companyName"
      :is-admin="company.isAdmin"
      @remove="removeItem(company.id)"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { getAll } from '@/api/providers/company';
import MaterialInput from '@/components/MaterialInput.vue';
import CompanyCard from '@/components/Company/CompanyCard.vue';
import CheckboxFilter from '@/components/UI/CheckboxFilter.vue';

export default {
  name: 'Companies',

  components: {
    CheckboxFilter,
    MaterialInput,
    CompanyCard,
  },

  data: () => ({
    filters: {
      isActivated: [],
    },
    items: [],
    searchQuery: '',
    showFilters: false,
  }),

  computed: {
    filteredCompanies() {
      return this.items
        .filter(this.searchFilter)
        .filter(this.filtersFilter);
    },

    lowerCaseSearchTerms() {
      return this.searchQuery.toLowerCase().split(' ');
    },
  },

  mounted() {
    this.loadCompanies();
    this.addActions();
  },

  methods: {
    ...mapActions('appHeader', [
      'addAction',
    ]),

    ...mapActions('notification', {
      addNotification: 'add',
    }),

    addActions() {
      this.addAction({
        handler: () => this.showFilters = !this.showFilters,
        icon: 'tune',
        text: 'Filters',
      });

      this.addAction({
        handler: () => this.$router.push({
          name: 'new-company',
        }),
        icon: 'domain_add',
        text: 'Nieuw',
      });
    },

    filtersFilter(item) {
      return Object.entries(this.filters)
        .every(([property, values]) => (
          values.includes(item[property])
          || !values.length
        ));
    },

    loadCompanies() {
      getAll()
        .then((data) => {
          this.items = data;
        })
        .catch((error) => error.default());
    },

    removeItem(id) {
      this.items.splice(this.items.findIndex((item) => item.id === id), 1);
    },

    searchFilter(item) {
      return !this.searchQuery.length
        ? true
        : Object.values(item)
            .filter((value) => {
              if (typeof value !== 'string') {
                return false;
              }

              const lowerCaseValue = value.toLowerCase();

              return this.lowerCaseSearchTerms
                .filter(
                  (lowerCaseSearchTerm) => lowerCaseValue.includes(lowerCaseSearchTerm)
                ).length;
            }).length
    },
  },
};
</script>

<style scoped lang="scss">
.filter-bar {
  height: 0;
  transition-property: height margin opacity;
  transition-duration: .2s;

  &:not(&--visible) {
    overflow: hidden;
    opacity: 0;
  }

  &--visible {
    height: 56.19px;
  }
}
</style>
