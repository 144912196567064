<template>
  <div class="company-card card">
    <div class="p-3 card-body">
      <div class="d-flex mt-n2">
        <div
          class="mt-n4 overflow-hidden flex-shrink-0"
        >
          <router-link
            :to="{
              name: 'edit-company',
              params: {
                id,
              }
            }"
            class="company-card__link stretched-link w-100 h-100"
          >
            <profile-picture 
              :width-and-height-in-pixels="75"
              :background-image-url="image"
            />
          </router-link>
        </div>
        <div class="my-auto ms-3">
          <h6 class="mb-0">{{ companyName }}</h6>
          
          <div
            v-if="isAdmin || boardMemberFunction"
            class="d-flex gap-2 mt-2"
          >
            <material-badge
              v-if="isAdmin"
              size="sm"
              color="warning"
            >
              Admin
            </material-badge>

            <material-badge
              v-if="boardMemberFunction"
              size="sm"
              color="secondary"
            >
              {{ boardMemberFunction }}
            </material-badge>

            <material-badge
              v-if="isActivated"
              size="sm"
              color="success"
            >
              Actief
            </material-badge>
          </div>
        </div>
        <div class="ms-auto">
          <dropdown-menu
            :items="[
              {
                handler: edit,
                name: 'Bewerken',
              },
              {
                handler: remove,
                name: 'Verwijderen',
              },
            ]"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { sendInvitation } from '@/api/providers/users'
import { remove } from '@/helpers/users';
import DropdownMenu from '@/components/UI/DropdownMenu.vue';
import MaterialBadge from '@/components/MaterialBadge.vue';
import ProfilePicture from '../UI/ProfilePicture.vue';

export default {
  name: 'CompanyCard',

  components: {
    DropdownMenu,
    MaterialBadge,
    ProfilePicture
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
    image: {
      type: String,
      default: "",
    },
    isActivated: {
      type: Boolean,
      required: true,
    },
    companyName: {
      type: String,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'remove',
  ],

  methods: {
    ...mapActions('notification', {
      addNotification: 'add',
    }),

    edit() {
      this.$router.push({
        name: 'edit-company',
        params: {
          id: this.id
        },
      });
    },

    remove() {
      remove(this.id, this.name)
        .then(() => {
          this.$emit('remove');
        })
        .catch((error) => error.default());
    },

    sendAppInvite() {
      sendInvitation(this.id)
        .then(() => {
          this.addNotification({
            description: `Er is een app-uitnodiging verzonden naar ${this.name}.`,
            icon: {
              component: 'mark_email_read',
            },
          });
        })
        .catch((error) => error.default());
    },
  },
};
</script>

<style scoped lang="scss">
.company-card {
  height: 100%;

  &__link {
    display: grid;
    place-content: center;
  }
}
</style>
